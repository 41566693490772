<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Examine the IR spectrum provided for compound B. The peaks below
        <stemble-latex content="$1500$" />
        cannot be used to make any structural assignments because they are in the fingerprint
        region.
      </p>

      <p class="mb-2">
        There are the two important peaks in this IR spectrum: peak 1 which is
        <stemble-latex content="$3400\,\text{cm}^{-1}$" />
        , and peak 2 which is
        <stemble-latex content="$2950\,\text{cm}^{-1}$" />
        .
      </p>

      <p class="mb-2">What is peak 1 most likely due to?</p>

      <v-radio-group v-model="inputs.input1" class="ml-6 mb-5" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsShuffled"
          :key="option.text"
          :value="option.value"
          class="my-1"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">What is peak 2 most likely due to?</p>

      <v-radio-group v-model="inputs.input2" class="ml-6 mb-5" :disabled="!allowEditing">
        <v-radio
          v-for="option in optionsShuffled"
          :key="option.text"
          :value="option.value"
          class="my-1"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LBA2M2Q3',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
      },
      options: [
        {text: '$\\ce{O-H or N-H}$', value: 'OHorNH'},
        {text: '$\\ce{C-H (sp^3)}$', value: 'CHsp3'},
        {text: '$\\ce{C-H (sp^2)}$', value: 'CHsp2'},
        {text: '$\\ce{C-H (sp)}$', value: 'CHsp'},
        {text: '$\\ce{C-O or N-O}$', value: 'COorNO'},
        {text: '$\\ce{C=O}$', value: 'CO'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
